import { ReactElement, useState } from "react";

import { styled } from "styled-components";

import { CardTitle } from "../styles/text";

interface VerticalCardProps {
  src: string;
  srcset?: string;
  alt: string;
  text: ReactElement;
  sizes?: string;
}

const ImageCard = styled.img`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 8px;
`;
const CardContainer = styled.div(
  () => `
  display: flex;
  position:relative;
  justify-content: space-between;
  border-radius: 13px;
  width: calc(50% - 8px);
  min-width: 120px;
  background-size: cover;
  background-position: center;
  `,
);
const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000 6.22%, rgb(0 0 0 / 0%) 67.06%);
`;
export const VerticalCard = ({
  src,
  srcset,
  alt,
  text,
  sizes,
}: VerticalCardProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <CardContainer>
      <ImageCard
        src={src}
        srcSet={srcset}
        alt={alt}
        sizes={sizes}
        onLoad={() => setImageLoaded(true)}
      />
      {imageLoaded && (
        <>
          <GradientOverlay />
          <CardTitle $fontSize="small" $lineHeight="143%">
            {text}
          </CardTitle>
        </>
      )}
    </CardContainer>
  );
};

import React, {
  InputHTMLAttributes,
  PropsWithChildren,
  forwardRef,
} from "react";

import { styled } from "styled-components";

const SwitchContainer = styled.label<{ $labelPosition: "left" | "right" }>(
  ({ $labelPosition }) => `
  cursor: pointer;
  display: inline-flex;
  flex-direction: ${$labelPosition === "left" ? "row" : "row-reverse"};
  gap: 8px;
`,
);

const InputContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  font-size: 20px;
  width: 2em;
`;

const SwitchInput = styled.input.attrs({ type: "checkbox" })(
  ({ theme }) => `
  position: absolute;
  appearance: none;
  opacity: 0;
  margin: 0;

  &:focus + span {
    outline: max(2px, 0.1em) solid ${theme.colors.grayMedium};
    outline-offset: max(2px, 0.05em);
  }

  &:checked + span {
    /* 38% opacity as hex is 61 */
    background-color: ${theme.colors.blueLight}61;
  }

  &:checked + span::after {
    background-color: ${theme.colors.blueLight};
    transform: translateX(20px);
  }
`,
);

const Slider = styled.span(
  ({ theme }) => `
    height: 0.7em;
    width: 100%;
    background-color: ${theme.colors.grayLightest};
    border-radius: 0.5rem;
    /* Positioning the ball */
    position: relative;
    display: inline-flex;
    align-items: center;

    &::after {
      content: "";
      position: absolute;
      width: 1em;
      height: 1em;
      background-color: ${theme.colors.white};
      border-radius: 50%;
      transition: transform 0.2s ease-in;
      transform: translateX(0);
    }
`,
);

interface SwitchProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
  label?: React.ReactNode;
  labelPosition?: "left" | "right";
}

export const Switch = forwardRef<
  HTMLInputElement,
  PropsWithChildren<SwitchProps>
>(({ label, labelPosition = "left", ...inputProps }, ref) => {
  const { id } = inputProps;
  return (
    <SwitchContainer htmlFor={id} $labelPosition={labelPosition}>
      {label}
      <InputContainer>
        <SwitchInput ref={ref} {...inputProps} />
        <Slider />
      </InputContainer>
    </SwitchContainer>
  );
});

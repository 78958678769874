import { PropsWithChildren, createContext, useContext, useState } from "react";

import { logoutUser } from "../../hooks/AccountApi";
import { AccountApiError } from "../../utils/AccountApiError";
import { Flags, useFlag } from "../../utils/featureFlags";
import { logger } from "../../utils/logger";

import { AxiosInterceptor } from "./AxiosInterceptor";
import { handleAxiosError } from "./handleAxiosError";

export interface TokenData {
  accessToken: string;
  refreshToken: string;
}

const REFRESH_PATHS = [
  "/api/v1/membership/graphql",
  "/api/v1/account/invoice",
  "/api/v1/account/password",
  "/api/v1/account/email",
  "/api/v1/account/logout",
];

interface AuthContextType {
  loggedIn: boolean;
  login: () => void;
  logout: (moiFlag: boolean, moiLogoutRedirectUrl?: string) => void;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("Use only inside Auth context");
  }
  return context;
};

interface AuthProviderProps extends PropsWithChildren {
  onLogin?: () => void;
  onLogout?: (moiFlag: boolean, moiLogoutRedirectUrl?: string) => void;
}

export const AuthProvider = ({
  children,
  onLogin,
  onLogout,
}: AuthProviderProps) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(
    JSON.parse(localStorage.getItem("isLoggedIn") ?? "false"),
  );

  const login = (): void => {
    localStorage.setItem("isLoggedIn", JSON.stringify(true));
    setLoggedIn(true);
    onLogin?.();
  };
  const moiFlag = useFlag(Flags.MOI_AUTH);
  const logout = async (moiFlag: boolean): Promise<void> => {
    if (moiFlag) {
      const moiLogoutResponse = await logoutUser().catch((error) => {
        if (error instanceof AccountApiError && error.code === "Unauthorized") {
          // Ignore 401 unauthorized, that happens if logout is called when not logged in.
          // The error should end up here only after interceptor has retries the error for expired access tokens.
          return;
        }
        logger.error("Error logging out user from moi", {
          errorMessage: error?.message,
        });
        throw error;
      });
      setLoggedIn(false);
      localStorage.removeItem("isLoggedIn");
      onLogout?.(moiFlag, moiLogoutResponse?.moiLogoutUrl);
    } else {
      await logoutUser().catch((error) => {
        if (error instanceof AccountApiError && error.code === "Unauthorized") {
          // Ignore 401 unauthorized, that happens if logout is called when not logged in.
          // The error should end up here only after interceptor has retries the error for expired access tokens.
          return;
        }
        logger.error("Error logging out user", {
          errorMessage: error?.message,
        });
        throw error;
      });
      setLoggedIn(false);
      localStorage.removeItem("isLoggedIn");
      onLogout?.(moiFlag);
    }
  };

  return (
    <AuthContext.Provider value={{ login, loggedIn, logout }}>
      <AxiosInterceptor
        errResInterceptor={(error) =>
          handleAxiosError(error, REFRESH_PATHS, logout, loggedIn, moiFlag)
        }
      >
        {children}
      </AxiosInterceptor>
    </AuthContext.Provider>
  );
};

import { type LogsEvent, StatusType, datadogLogs } from "@datadog/browser-logs";
import * as Sentry from "@sentry/react";
import fastRedact from "fast-redact";

import { ERROR_MAPPER } from "./errorHandler";

const ENV = import.meta.env.MODE;
const isDev = import.meta.env.DEV;
const CLIENT_TOKEN: string = import.meta.env.VITE_DD_CLIENT_TOKEN;
const SERVICE_NAME: string = import.meta.env.VITE_DD_SERVICE;

const logLevelToSentryLevel = (
  level: StatusType,
): Sentry.SeverityLevel | undefined => {
  switch (level) {
    case "error":
    case "info":
    case "debug":
      return level;
    case "warn":
      return "warning";
    default: {
      Sentry.captureMessage(
        `Developer error: DD log level '${String(level)}' cannot be converted to Sentry level`,
        {
          level: "fatal",
        },
      );
      return "fatal";
    }
  }
};

const addLogToSentryBreadcrumb = (log: LogsEvent) => {
  const { message, status: level, ...data } = log;
  const sentryLevel = logLevelToSentryLevel(level);
  Sentry.addBreadcrumb({
    category: "console",
    message,
    level: sentryLevel,
    data,
  });
};

// https://developer.paypal.com/braintree/articles/control-panel/transactions/declines
const CC_ERRORS = [
  "Transaction declined.2001 - Insufficient Funds",
  "Transaction declined.2002 - Limit Exceeded",
  "Transaction declined.2004 - Expired Card",
  "Transaction declined.2108 - Closed Card",
  "Transaction declined.2010 - Card Issuer Declined CVV",
].map((s) => RegExp(s));

const IGNORED_MESSAGES: RegExp[] = [
  RegExp(`Error: ${ERROR_MAPPER.missingSignupToken}`), // Matches to specific React Router error as well. Below catches rest of them.
  /React Router caught the following error/,
  ...CC_ERRORS,
  /Fetch error.+https:\/\/api.segment.io\/v1/,
  /Fetch error.+https:\/\/cdn.segment.com\/v1/,
  /^Error sending segment performance metrics/,
  /^\[analytics\.js\]/,
  /Fetch error.+https:\/\/featuregates.org\/v1\/initialize/,
  /Fetch error.+https:\/\/events.statsigapi.net\/v1\/rgstr/,
  /Fetch error.+https:\/\/statsigapi.net\/v1\/sdk_exception/,
  /XHR error POST https:\/\/www.paypal.com\/xoplatform\/logger/,
];

// Mutate log level of chosen logs to remove them from Sentry
export const mutateExternalLogLevel = (log: LogsEvent): void => {
  if (IGNORED_MESSAGES.some((re) => re.test(log.message))) {
    log.status = "debug";
  }
};

const hackyPathGenerationForDeeperLevels = (paths: string[], levels = 10) => {
  const censorPadding = "*.";
  return paths.flatMap((path) => {
    return Array.from(
      { length: levels },
      (_, i) => `${censorPadding.repeat(i)}${path}`,
    );
  });
};

export const REDACTED_PATHS = hackyPathGenerationForDeeperLevels([
  "email",
  "personalEmail",
  "workEmail",
  "emails",
  "password",
  "data",
  "body",
  // Some common headers
  "authorization",
  'headers["x-oura-auth-signature"]',
  'headers["x-api-key"]',
  'headers["x-forwarded-for"]',
  "ip",
  "ipAddress",
  // Contact information
  "accountName",
  "basicInfo",
  "billToContact",
  "shipToContact",
  "billingAddress",
  "shippingAddress",
  "billingInfo",
  "shippingInfo",
  "accountHolderInfo",
  "accountDetails",
  "firstName",
  "lastName",
  "address",
  "address1",
  "address2",
  "city",
  "state",
  "postalCode",
  "zipCode",
  // Tokens and signatures
  "access_token",
  "jti",
  "key",
  "signature",
  "token",
]);

const redactionFn = fastRedact({
  paths: REDACTED_PATHS,
  censor: "[REDACTED]",
  serialize: false,
});

export const redact = (log: LogsEvent): void => {
  Object.assign(log, redactionFn(log));
  return;
};

datadogLogs.init({
  site: "datadoghq.com",
  clientToken: CLIENT_TOKEN,
  service: SERVICE_NAME,
  env: ENV,
  forwardErrorsToLogs: true, // Set to false to stop forwarding console.error logs, uncaught exceptions and network errors to Datadog.
  forwardConsoleLogs: "all", // Forward logs from console.* to Datadog. Use "all" to forward everything or an array of console API names to forward only a subset.
  sessionSampleRate: 100,
  telemetrySampleRate: 0, // Opt-out of DD telemetry
  storeContextsAcrossPages: false, // We don't use context yet

  beforeSend: (log) => {
    redact(log);

    // Change log level of some logged errors
    mutateExternalLogLevel(log);
    addLogToSentryBreadcrumb(log);
    return true;
  },
});

export const logger = datadogLogs.logger;
if (!isDev) {
  logger.setHandler("http");
}

import { useEffect, useState } from "react";

import { breakpoints } from "../themes/signup";

type Breakpoint = keyof typeof breakpoints;

type IsDesktopViewType = boolean;
type WindowWidthType = number;
type UseIsDesktopViewResponse = [IsDesktopViewType, WindowWidthType];

export const useIsDesktopView = (
  breakpoint: Breakpoint = "xlarge",
): UseIsDesktopViewResponse => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [windowWidth > parseInt(breakpoints[breakpoint], 10), windowWidth];
};

import axios, { AxiosError, type AxiosRequestConfig } from "axios";

import { refreshTokens } from "../../hooks/AccountApi";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

export const handleAxiosError = async (
  error: AxiosError,
  paths: string[],
  logout: (moiFlag: boolean) => void,
  loggedIn: boolean,
  moiFlag: boolean,
) => {
  // We have to check if url exists, in some paypal cases it might be missing
  if (!error.config?.url) return Promise.reject(error);

  // Check if the path is eligible for refresh
  const { pathname } = new URL(error.config.url);
  const isEligibleEndpoint = paths.includes(pathname);

  const isHTTP401 = error.response?.status === 401;
  // Check if the request has not been retried yet
  const notRetried = !(error.config as CustomAxiosRequestConfig)?._retry;

  if (isEligibleEndpoint && isHTTP401 && notRetried) {
    try {
      // Set the retry flag to avoid infinite loops
      (error.config as CustomAxiosRequestConfig)._retry = true;
      // takes the existing refresh token and returns new auth and refresh token
      await refreshTokens();
      // Retry the original request with the new access token
      return axios(error.config);
    } catch (refreshError) {
      // Handle token refresh failure, logout the user (fires the onLogout given as props from app)
      if (loggedIn) {
        logout(moiFlag);
      }
      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      return Promise.reject(refreshError);
    }
  }
  return Promise.reject(error);
};

import { css, styled } from "styled-components";

import { media } from "../themes/signup";

interface Props {
  $width?: string;
  $margin?: string;
}
export const RingImage = styled.img<Props>(
  ({ $width = "80%", $margin = "0" }) => css`
    width: ${$width};
    margin: ${$margin};

    ${media.large`
   width: 393px;
   `}

    ${media.xlarge`
   transform: translateY(-20%);
   `}
  `,
);

import {
  Dispatch,
  KeyboardEvent,
  ReactNode,
  SetStateAction,
  useRef,
} from "react";

import { styled } from "styled-components";

import { Paragraph } from "../styles/text";

interface PopoverProps {
  content: ReactNode;
  isOpen: boolean;
}
interface PopoverContainerProps {
  setShowPopover: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
  isOpen: boolean;
  popoverEnabled: boolean;
}

const POPOVER_ARROW_HEIGHT = 7;

const StyledPopover = styled.div(
  ({ theme }) => `
  position: absolute;
  left: 10px;
  bottom: 110%;
  width: 100%;
  background-color: ${theme.colors.grayMedium};
  color: ${theme.colors.grayLightest};
  z-index: 10;
  padding: 14px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid ${theme.colors.grayMedium};

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10px;
    width: 0;
    height: 0;
    border-left: ${POPOVER_ARROW_HEIGHT}px solid transparent;
    border-right: ${POPOVER_ARROW_HEIGHT}px solid transparent;
    border-top: ${POPOVER_ARROW_HEIGHT}px solid ${theme.colors.grayMedium};
  }
`,
);

const StyledPopoverContainer = styled.div`
  position: relative;
`;

export const Popover = ({ content, isOpen }: PopoverProps) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {isOpen && (
        <StyledPopover ref={popoverRef}>
          <Paragraph $fontSize="small">{content}</Paragraph>
        </StyledPopover>
      )}
    </>
  );
};

export const PopoverContainer = ({
  setShowPopover,
  isOpen,
  children,
  popoverEnabled,
}: PopoverContainerProps) => {
  // Handlers for mouse enter and leave
  const handleMouseEnter = () => {
    setShowPopover(true);
  };

  const handleMouseLeave = () => {
    setShowPopover(false);
  };

  // Handler for keyboard events
  const togglePopover = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      setShowPopover(!isOpen);
      event.preventDefault();
    }
  };

  return (
    // eslint-disable-next-line styled-components-a11y/no-static-element-interactions
    <StyledPopoverContainer
      role={popoverEnabled ? "button" : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      // eslint-disable-next-line styled-components-a11y/no-noninteractive-tabindex
      tabIndex={popoverEnabled ? 0 : -1}
      onKeyDown={togglePopover}
      onBlur={() => setShowPopover(false)}
      aria-haspopup={popoverEnabled ? "true" : undefined}
      aria-expanded={isOpen ? "true" : "false"}
      aria-disabled={popoverEnabled ? "false" : "true"}
    >
      {children}
    </StyledPopoverContainer>
  );
};

import { styled } from "styled-components";
import { t } from "translations";

import { Background } from "../../styles/layout";
import { Paragraph } from "../../styles/text";
import { media } from "../../themes/signup";

const PaddedBg = styled(Background)`
  padding: 10px;
  border-radius: 0;
  ${media.medium`
     padding: 20px 0;
  `}
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  text-align: center;
  background-color: hsl(225deg 11% 95% / 10%);

  p {
    margin: 0;
    font-weight: bold;
  }
`;

export const RingPairingNotification = () => (
  <PaddedBg>
    <Paragraph $fontSize="small">
      {t("membership_setup_ring_pair_failure_notification")}.
    </Paragraph>
  </PaddedBg>
);
